<template>
  <div>
    <b-overlay
      :show="mainOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-large
      style="top: 25em;"
      rounded="sm"
    >
    </b-overlay>
    <!-- Overview -->
    <b-card
      v-if="false"
      class="earnings-card"
      style="padding: 1em"
    >
      <b-overlay
        :show="tableOverlay"
        rounded="sm"
      >
        <b-row>
          <b-col lg="8" class="mb-1">
            <!-- <b-card-title class="mb-1">
              Suma total
            </b-card-title> -->
            <h4 class="text-center mb-2 mt-2">Suma total</h4>
            <!--  -->
            <b-row align-h="center">
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="mb-2 d-flex justify-content-center"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-success"
                      >
                        <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        ${{ formatterNumber(totalSum) }}
                      </h4>
                      <b-card-text class=" font-medium-2 mb-0">
                        Total
                      </b-card-text>
                      <b-card-text class="text-muted font-small-3">
                        <span>Dividido entre </span><span class="font-weight-bolder">{{ companies.length-1 }}</span><span> diferentes proveedores.</span>
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
              <!--  -->
            <!-- <div class="font-medium-2">
              Pesos MXN
            </div> -->
            <!-- <h5 class="mb-1">
              ${{ formatterNumber(totalSum) }}
            </h5> -->
            <!-- <b-form-checkbox
              v-if="!this.$can('read', 'outputInventory')"
              class="ml-2 mt-2 mb-2 mr-2"
              switch
              v-model="moreOptions"
            >
              <h5>
                Tabla Detallada
              </h5>
            </b-form-checkbox> -->

          </b-col>
          <b-col lg="4" class="mb-1">
            <h4 class="text-center mb-2 mt-2">Detalles</h4>
            <b-row align-h="center">
              <b-col
                lg="12"
                md="12"
                sm="12"
                class="mb-2 d-flex justify-content-center"
              >
              <b-form-checkbox
                v-if="!this.$can('read', 'outputInventory')"
                class="ml-2 mt-2 mb-2 mr-2"
                switch
                v-model="moreOptions"
              >
                <h5>
                  Tabla Detallada
                </h5>
              </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

    <!-- Companies List -->
    <b-tabs
      pills
      v-model="tabIndex"
    >
      <b-tab v-for="company in companies" :key="company.companyId">
        <template #title>
          <feather-icon
            :icon="company.companyId ? 'UserIcon' : 'AlignCenterIcon'"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold"> {{ company.name }} </span>
        </template>
        <b-card>
          <b-overlay
            :show="tableOverlay"
            rounded="sm"
          >
            <!-- Inventory -->
            <b-col lg="12" class="mb-1" v-if="!$can('read', 'outputInventory') && company.name != 'General'">
              <h4 class="text-center mb-2 mt-2">Kilos totales</h4>
              <b-row align-h="center">
                <b-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="mb-2 d-flex justify-content-center"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-success"
                      >
                        <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ formatterNumber(parseFloat(inventories[tabIndex].totalQuantity)) }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Total
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-col>
            <div class="table-responsive">
                <div>
                  <b-table
                    striped
                    hover
                    responsive
                    :items="inventories[tabIndex].inventory"
                    :fields="moreOptions ? detailedTable : simpleTable"
                    primary-key="name"
                    :sort-by.sync="sortBy"
                    :sort-desc="true"
                    show-empty
                    empty-text="No hay registros"
                    class="position-relative"
                    @row-clicked="showEditMenu($event, company.companyId)"
                  >

                    <template #cell(quantity)="data">
                      {{ formatterNumber(data.value) }} kgs
                    </template>

                    <template #cell(value)="data">
                      ${{ formatterNumber(data.value) }}
                    </template>

                    <template #cell(selectedPrice)="data">
                      ${{ formatterNumber(data.value) }}
                    </template>

                    <template #cell(pValue)="data">
                      ${{ formatterNumber(data.item.value/data.item.quantity) }}
                    </template>

                    <template #cell(percentage)="data">
                      {{ 0 }}%
<!--                      {{ formatterNumber(data.value.toFixed(0)) }}%-->
                    </template>

                    <template #cell(sellValue)="data">
                      <template v-if="data.value > 0">
                        ${{ formatterNumber(data.value) }}
                      </template>
                      <template v-else>
                        No definido
                      </template>
                    </template>

                    <template #cell(utility)="data">
                      <template v-if="data.value">
                        ${{ formatterNumber(data.value) }}
                      </template>
                      <template v-else>
                        No definido
                      </template>
                    </template>

                    <template #cell(percentageUtility)="data">
                      <template v-if="data.value">
                        {{ formatterNumber(data.value.toFixed(0)) }}%
                      </template>
                      <template v-else>
                        N/A
                      </template>
                    </template>

                    <template #cell(edit)>
                      <feather-icon
                        icon="Edit2Icon"
                        size="16"
                        class="mx-1"
                      />
                    </template>

                  </b-table>
                </div>
            </div>
          </b-overlay>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-modal
      id="editModal"
      title="Editar cantidad"
      ok-title="Editar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      :ok-disabled="!editCheckbox ||  itemModifiedQuantity === '' || itemModifiedQuantity > itemQuantity"
      @ok="editQuantity()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <!-- Edit -->
      <b-form-group :label="'Cantidad a restar de ' + itemName ">
        <b-input-group append="kg">
          <b-form-input
            type="number"
            min="1"
            @keypress="restrictNumber($event)"
            v-model="itemModifiedQuantity"
            :formatter="limitAmount"
          >
          </b-form-input>
        </b-input-group>
      </b-form-group>

        {{ parseFloat(itemQuantity) }} - {{ parseFloat(itemModifiedQuantity) }} =
        {{ (parseFloat(itemQuantity) - parseFloat(itemModifiedQuantity)).toFixed(2) }}
        <hr>

      <!-- Warning -->
      <div class="mt-3">
        <p style="font-size: medium">Esta acción afectará al inventario</p>
        <b-form-checkbox
          class="custom-control-warning"
          v-model="editCheckbox"
        >
          Estoy seguro de que quiero realizar este cambio en <b>{{itemName}}</b>.
        </b-form-checkbox>
      </div>

    </b-modal>

  </div>
</template>


<script>
import { BCard, BTabs, BTab, BOverlay, BTable,
BCardText, BCardTitle, BRow, BCol, BMediaAside, BMedia,
BMediaBody, BAvatar, BFormGroup, BFormInput, BFormCheckbox, BInputGroup } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { verifyToken, refreshToken } from '@/auth/utils';
import utils from '@/utils/utils.js';

const $chartColors = ['#28c76f', '#28c76f66', '#28c76f33'];

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BOverlay,
    BTable,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BMediaAside,
    BMedia,
    BMediaBody,
    BAvatar,
    VueApexCharts,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup
  },
  data() {
    return {
      // Data
      companies: [],
      inventories: [],
      totals: [],
      totalSum: 0,
      generalInventory: [],
      moreOptions: false,
      user: JSON.parse(localStorage.getItem("user")),

      // Chart
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            id: 'earningsChart',
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          labels: [],
          stroke: { width: 0 },
          colors: [],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseFloat(val).toFixed(2)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Total',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 140,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 140,
                },
              },
            },
          ],
        },
      },

      // Table
      tableColumns: [],
      sortBy: 'percentage',

      // Props
      counts: 0,
      mainOverlay: true,
      tableOverlay: true,
      tabIndex: 0,
      itemQuantityReal: 0,
      inventoryId: null,
      itemName: null,
      itemQuantity: 0,
      itemModifiedQuantity: 0,
      companyId: null,
      editCheckbox: false,
      simpleTable : [
        { key: 'name', label: 'Item', sortable: true },
        { key: 'quantity', label: 'Cantidad', sortable: true },
        // { key: 'value', label: 'T C', sortable: true },
        // { key: 'percentage', label: 'Representa', sortable: true },
      ],
      detailedTable : [
        { key: 'name', label: 'Item', sortable: true },
        { key: 'quantity', label: 'Cantidad', sortable: true },
        { key: 'value', label: 'T C', sortable: true },
        { key: 'sellValue', label: 'T V', sortable: true },
        { key: 'utility', label: '$ U', sortable: true },
        { key: 'percentageUtility', label: '% U', sortable: true },
        { key: 'pValue', label: 'P C', sortable: true },
        { key: 'selectedPrice', label: 'P V', sortable: true },
        { key: 'percentage', label: '% $', sortable: true },
      ],

    }
  },
  created() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {

      this.inventories = []
      this.companies = []
      this.totals = []
      this.totalSum = 0;

      // this.$axios.post(this.$services + 'companies/get_companies').then(res => {
      //   if (!res.data.error) {
      //     this.companies.push({"name": "General"});
      //     this.companies.push(...res.data.data);
      //
      //     // if (this.$can('read', 'Calculator'))
      //     this.companies = this.companies.filter(company => company.companyId === this.user.companyId);
      //     this.inventories = new Array(this.companies.length).fill(null);
      //     this.totals = new Array(this.companies.length).fill(0);
      //     this.companies.forEach((company, index) => {
      //       this.getInventory(company.companyId, index);
      //     });
      //   }
      // }).finally(() => {
      //   this.mainOverlay = false;
      // });
        this.companies.push({"name": this.user.companyName, companyId: this.user.companyId });
        this.companies = this.companies.filter(company => company.companyId === this.user.companyId);
        this.inventories = new Array(this.companies.length).fill(null);
        this.totals = new Array(this.companies.length).fill(0);
        this.companies.forEach((company, index) => {
            this.getInventory(company.companyId, index);
        });

        this.mainOverlay = false;

    },
    getInventory(companyId, index) {
      if (companyId) {
        this.$axios.post(this.$services + 'manager/findInventoryApi', {
            companyId: companyId
        }).then(res => {
          if (res.data) {
            const inventory = res.data
            this.inventories.splice(index, 1, inventory);
            // this.setGeneralInformation(inventory)

            // this.counts += 1;
            // if (this.counts == this.companies.length-1) {  // Si es el último loop
              // this.setChartData();
            // }
          }
        }).catch(error => {
        }).finally(() => {
          this.tableOverlay = false;

          // this.inventories[0] = this.generalInventory; // Asignar valores al principio

          // let totalMoneyInventory = this.inventories[0].reduce((total, next) => total + next.value, 0);

          // this.inventories[0].forEach(element => {
          //   element.percentage = (element.value / totalMoneyInventory) * 100
          // });

        });
      }
    },
    setGeneralInformation(inventory) {
      // inventory.forEach(element => {
      //   let item = this.generalInventory.find(item => item.inventoryId == element.inventoryId);
      //   if (item) {
      //     item.quantity += element.quantity;
      //     item.value += element.value;
      //     item.sellValue += element.sellValue;
      //     item.utility += element.utility;
      //   } else {
      //     this.generalInventory.push(JSON.parse(JSON.stringify(element)))
      //   }
      // });
    },
    setChartData() {

      // Preparar variables
      let chart = ApexCharts.getChartByID('earningsChart');
      let i = 0;

      // Empujar datos
      this.companies.forEach((company, index) => {
        if (company.companyId) { // Evitar General
          this.earningsChart.series.push(parseInt((this.totals[index] / this.totalSum) * 100));
          this.earningsChart.chartOptions.labels.push(company.name);
          this.earningsChart.chartOptions.colors.push($chartColors[i % $chartColors.length]);
          i += 1;
        }
      });

      // Actualizar datos
      chart.updateSeries(this.earningsChart.series);
      chart.updateOptions(this.earningsChart.chartOptions);
    },
    showEditMenu(event, companyId) {
      if (companyId !== undefined) {
        this.inventoryId = event.inventoryId
        this.itemName = event.name
        this.itemQuantity = event.quantity;
        this.itemModifiedQuantity = 1;
        this.editCheckbox = false;
        this.$bvModal.show('editModal');
      }
    },
    editQuantity() {
      this.mainOverlay = true;
      this.tableOverlay = true;

      this.$axios.post(this.$services+'manager/changeInventoryApi', {
          inventoryId: this.inventoryId,
          itemModifiedQuantity: this.itemModifiedQuantity
      }).then(res => {
          this.showToast('success', String(`¡${this.itemName} modificado con éxito!`));
      }).catch(error => {
        this.showToast('danger', String(error));
      }).finally(() => {
        this.getCompanies();
      });
    },
    restrictNumber(event) {  // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) {  // 46 is dot
        event.preventDefault();
        return;
      }
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    limitAmount(e){
      if (parseInt(e) >= this.itemQuantity) {
        this.itemQuantityReal = this.itemQuantity;
        return this.itemQuantity.toFixed(2);
      } else if (parseInt(e) < 0) {
        return 0;
      } else {
        this.itemQuantityReal = e;
        return e;
      }
    },
  }
}
</script>
